import React, { Fragment, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
/* eslint-disable jsx-a11y/anchor-is-valid */

const PublicHome = (props) => {
  // /* Image Slider - Swiper */
  // var imageSlider = new Swiper('.image-slider', {
  //   autoplay: {
  //     delay: 2000,
  //     disableOnInteraction: false
  //   },
  //   loop: true,
  //   spaceBetween: 30,
  //   slidesPerView: 5,
  //   breakpoints: {
  //     // when window is <= 575px
  //     575: {
  //       slidesPerView: 1,
  //       spaceBetween: 10
  //     },
  //     // when window is <= 767px
  //     767: {
  //       slidesPerView: 2,
  //       spaceBetween: 20
  //     },
  //     // when window is <= 991px
  //     991: {
  //       slidesPerView: 3,
  //       spaceBetween: 20
  //     },
  //     // when window is <= 1199px
  //     1199: {
  //       slidesPerView: 4,
  //       spaceBetween: 20
  //     },

  //   }
  // });


  // /* Text Slider - Swiper */
  // var textSlider = new Swiper('.text-slider', {
  //   autoplay: {
  //     delay: 6000,
  //     disableOnInteraction: false
  //   },
  //   loop: true,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   }
  // });


  /* Video Modal */
  // var videoBtn = document.querySelector('.video-btn')
  // var videoModal = document.getElementById('videoModal')
  // var video = document.getElementById('video')
  // var videoSrc

  // var checkVideoSrc = document.querySelector('.video-btn');
  // if (checkVideoSrc !== null) { 
  //   videoBtn.addEventListener('click',function(e){
  //     videoSrc = videoBtn.getAttribute('data-bs-src')
  //     console.log(1)
  //   })
  // }

  // var checkVideoModal = document.getElementById('videoModal');
  // if (checkVideoModal !== null) { 
  //   videoModal.addEventListener('shown.bs.modal',(e)=>{
  //     video.setAttribute('src', videoSrc + '?autoplay=1&amp;modestbranding=1&amp;showinfo=0')
  //     console.log(2)
  //   })

  //   videoModal.addEventListener('hide.bs.modal',(e)=>{
  //     video.setAttribute('src', videoSrc)
  //     console.log(3)
  //   })
  // }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const [agree, setAgree] = useState(false);

  // const checkboxHandler = () => {
  //   // if agree === true, it will be set to false
  //   // if agree === false, it will be set to true
  //   setAgree(!agree);
  //   // Don't miss the exclamation mark
  // }

  // const formHiddenStyle = {
  //   "position": "absolute",
  //   "left": "-5000px"
  // }

  return (
    <Fragment>
      {/* <!-- Header --> */}
      <header id="header" className="header">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="text-container">
                <h1 className="h1-large">Nahwall: sync your calendars</h1>
                <blockquote className="blockquote">
                  <p className="quotation">"I love my Calendar!"</p>
                  <footer className="blockquote-footer quotation">said no one ever</footer>
                </blockquote>
                <p className="p-large">Juggling multiple calendars, got people booking meetings when your other calendar is busy, trying to manually keep them in sync like it's the 1600's?</p>
                <p className="p-large">Stop, and let Nahwall start.</p>
                <a className="btn-solid-lg" href="#features">Discover</a>
                {/* <a className="btn-outline-lg" onClick={props.auth.openSignup} href="#/">Sign Up</a> */}
                <a className="btn-outline-lg" href="#launch">I'm Interested</a>
              </div> {/* <!-- end of text-container --> */}
            </Col> {/* <!-- end of div --> */}
            <Col lg={6}>
              <div className="image-container">
                <img className="img-fluid" src="images/header4.svg" alt="alternative" />
                {/* <img className="img-fluid" src={process.env.PUBLIC_URL + '/images/Nahwall.svg'} alt="alternative" /> */}
              </div> {/* <!-- end of image-container --> */}
            </Col> {/* <!-- end of col --> */}
          </Row> {/* <!-- end of row --> */}
        </Container> {/* <!-- end of container --> */}
        <svg className="header-decoration" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 305.139"><defs><style>{`.cls-1{fill:#ffffff;}`}</style></defs><title>header-decoration</title><path className="cls-1" d="M1486.486,36.773C1434.531,13.658,1401.068-5.1,1329.052,1.251c-92.939,8.2-152.759,70.189-180.71,89.478-23.154,15.979-134.286,104.091-171.753,128.16-50.559,32.48-98.365,59.228-166.492,67.5-67.648,8.21-124.574-6.25-152.992-18-42.218-17.454-42.218-17.454-90-39-35.411-15.967-81.61-34.073-141.58-34.054-116.6.037-262.78,77.981-354.895,80.062C53.1,275.793,22.75,273.566,0,260.566v44.573H1920V61.316c-36.724,23.238-76.008,61.68-177,65C1655.415,129.2,1556.216,67.8,1486.486,36.773Z" transform="translate(0 0)"/></svg>
      </header>
      {/* <!-- end of header --> */}

      {/* <!-- Description --> */}
      <div id="features" className="cards-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">FEATURES</div>
              <h2 className="h2-heading">Saves time and hassle</h2>
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
          <div className="row">
            <div className="col-lg-12">
                
              {/* <!-- Card --> */}
              <div className="card">
                <div className="card-image">
                  <img className="img-fluid" src="images/description-icon-1.svg" alt="alternative" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Automated Calendar Sync</h5>
                  <p>When a meeting is booked in one of your calendars, synchronise this with your other calendars.</p>
                </div>
              </div>
              {/* <!-- end of card --> */}

              {/* <!-- Card --> */}
              <div className="card">
                <div className="card-image">
                  <img className="img-fluid" src="images/description-icon-2.svg" alt="alternative" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Reliable Calendar Availability</h5>
                  <p>All your calendars will show your true availability, factoring in all other calendars. Calendars, calendars, calendars.</p>
                </div>
              </div>
              {/* <!-- end of card --> */}

              {/* <!-- Card --> */}
              <div className="card">
                <div className="card-image">
                  <img className="img-fluid" src="images/description-icon-3.svg" alt="alternative" />
                </div>
                <div className="card-body">
                  <h5 className="card-title">Reduce Wasted Time</h5>
                  <p>Avoid these conversations: "hey I booked a meeting", "argh I have a meeting in my other calendar then".</p>
                </div>
              </div>
              {/* <!-- end of card --> */}

            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
          {/* <a className="btn-solid-reg" onClick={props.auth.openSignup} href="#/">Sign Up</a> */}
          <a className="btn-solid-reg" href="#launch">I'm Interested</a>
        </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of cards-1 --> */}
      {/* <!-- end of description --> */}

      {/* <!-- Video --> */}
      <div className="basic-2 bg-gray cards-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">DEMO</div>
              <h2 className="h2-heading">See how it works</h2>
            </div> {/* <!-- end of col --> */}
            <div className="col-lg-12">
              {/* <!-- Video Preview --> */}
              <div className="image-container">
                <div className="video-wrapper">
                  {/* <img className="img-fluid" src="images/video-preview.svg" alt="alternative" /> */}
                  <img className="img-fluid" src="images/demoanimation.gif" alt="alternative" />
                  <a className="video-btn" data-bs-toggle="modal" data-bs-target="#videoModal" data-bs-src="https://www.youtube.com/embed/fLCjQJCekTs" onClick={handleShow}>
                    <span className="video-play-button">
                      <span></span>
                    </span>
                  </a>
                </div> {/* <!-- end of video-wrapper --> */}
              </div> {/* <!-- end of image-container --> */}
              {/* <!-- end of video preview --> */}
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of basic-2 --> */}
      {/* <!-- end of video --> */}


      {/* <!-- Video Modal --> */}
      <Modal className="video-modal modal" show={show} onHide={handleClose}>
        <div className="ratio ratio-16x9">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/1n-eK58b0A4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Modal>
      {/* <!-- end of video Modal --> */}

      {/* <!-- Pricing --> */}
      <div id="pricing" className="cards-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">NAHWALL PRICING</div>
              <h2 className="h2-heading">Yep, just one flat price</h2>
            </div> {/* <!-- end of col --> */}
            <div className="col-lg-12">

              {/* <!-- Card--> */}
              <div className="card">
                <div className="card-body">
                  <div className="card-title">CUSTOMER</div>
                  <div className="price"><span className="currency">US$</span><span className="value">6.00</span></div>
                  <div className="frequency">monthly</div>
                  <hr className="cell-divide-hr" />
                  <ul className="list-unstyled li-space-lg">
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">7 day free trial</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Sync up to four calendars</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Single and recurring events</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Microsoft and Google</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Happily get on with your life</div>
                    </li>
                  </ul>
                  <div className="button-wrapper">
                    {/* <a className="btn-solid-reg" onClick={props.auth.openSignup} href="#/">Sign Up</a> */}
                    <a className="btn-solid-reg" href="#launch">I'm Interested</a>
                  </div>
                </div>
              </div> {/* <!-- end of card --> */}
              {/* <!-- end of card --> */}

              {/* <!-- Card--> */}
              <div className="card">
                <div className="card-body">
                  <div className="card-title">STILL A CUSTOMER</div>
                  <div className="price"><span className="currency">US$</span><span className="value">6.00</span></div>
                  <div className="frequency">monthly</div>
                  <hr className="cell-divide-hr" />
                  <ul className="list-unstyled li-space-lg">
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">7 day free trial</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Sync up to four calendars</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Single and recurring events</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Microsoft and Google</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Happily get on with your life</div>
                    </li>
                  </ul>
                  <div className="button-wrapper">
                    {/* <a className="btn-solid-reg" onClick={props.auth.openSignup} href="#/">Sign Up</a> */}
                    <a className="btn-solid-reg" href="#launch">I'm Interested</a>
                  </div>
                </div>
              </div> {/* <!-- end of card --> */}
              {/* <!-- end of card --> */}

              {/* <!-- Card--> */}
              <div className="card">
                <div className="card-body">
                  <div className="card-title">ALSO A CUSTOMER</div>
                  <div className="price"><span className="currency">US$</span><span className="value">6.00</span></div>
                  <div className="frequency">monthly</div>
                  <hr className="cell-divide-hr" />
                  <ul className="list-unstyled li-space-lg">
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">7 day free trial</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Sync up to four calendars</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Single and recurring events</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Microsoft and Google</div>
                    </li>
                    <li className="d-flex">
                      <div className="icon-on"><FontAwesomeIcon icon={solid('check')} /></div>
                      <div className="flex-grow-1">Happily get on with your life</div>
                    </li>
                  </ul>
                  <div className="button-wrapper">
                    {/* <a className="btn-solid-reg" onClick={props.auth.openSignup} href="#/">Sign Up</a> */}
                    <a className="btn-solid-reg" href="#launch">I'm Interested</a>
                  </div>
                </div>
              </div> {/* <!-- end of card --> */}
              {/* <!-- end of card --> */}

            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of cards-2 --> */}
      {/* <!-- end of pricing --> */}

      {/* <!-- Newsletter --> */}
      <div id="launch" className="form-1">
          <div className="container">
              <div className="row">
                  <div className="col-lg-12">
                      <div className="form-container bg-gray">
                          {/* <div className="section-title">WE'RE IN PRIVATE BETA</div> */}
                          <div className="section-title">JOIN OUR MAILING LIST</div>
                          {/* <h2 className="h2-heading">We'll let you in when we can, or tell you when we launch</h2> */}
                          <h2 className="h2-heading">We'll keep you updated as Nahwall gets even better</h2>

                          {/* <!-- Launch Waitlist Form --> */}
                          <div data-form-uid="O79OPGWE"></div>
                          {/* <form action="https://nahwall.outseta.com/email/lists/8vW5zNm4/subscribe" method="post">

                            <div className="mb-4 form-floating">
                              <input type="email" className="form-control" id="Email" name="Email" placeholder="name@example.com" required />
                              <label htmlFor="Email">Email</label>
                            </div>
                            <div className="mb-4 form-check">
                              <input type="checkbox" className="form-check-input" id="exampleCheck1" onChange={checkboxHandler} />
                              <label className="form-check-label" htmlFor="exampleCheck1">I agree to the Nahwall <a href="/privacy" target="_blank">Privacy Policy</a> and <a href="/terms" target="_blank">Terms of Service</a></label>
                            </div> */}

                            {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                            {/* <div style={formHiddenStyle} aria-hidden="true">
                              <input type="text" name="o_qrWff6INRZo0d7H4TJD9kyeS_lf6JXR41" tabIndex="-1" value="" readOnly />
                            </div>

                            <div className="clear">
                              <input disabled={!agree} className="form-control-submit-button" type="submit" value="Subscribe" data-wait="Please wait..." />
                            </div>
                          </form> */}
                          {/* <!-- end of newsletter form --> */}

                      </div> {/* <!-- end of form-container --> */}
                  </div> {/* <!-- end of col --> */}
              </div> {/* <!-- end of row --> */}
          </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of form-1 --> */}
      {/* <!-- end of newsletter --> */}

    </Fragment>
  )
}

export default PublicHome;