import React, { Fragment } from 'react';
import { useAuth } from '../AuthProvider';
import Header from './Header';
import Footer from './Footer';
import HeaderEx from './utility/HeaderEx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const Terms = () => {
  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  } = useAuth();
  // console.log('useAuth() Terms');
  const authProps = {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  };

  return (
    <Fragment>
      <Header auth={authProps}/>
      <HeaderEx title="Terms of Service"></HeaderEx>
      <div className="ex-basic-1 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">

            <h2>Terms of Service</h2>
            <p>These Terms of Service govern your use of the website located at <a href="https://nahwall.com">https://nahwall.com</a> and any related services provided by Working Digital Pty Ltd. </p>
            <p>By accessing <a href="https://nahwall.com">https://nahwall.com</a>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Working Digital Pty Ltd. </p>
            <p>We, Working Digital Pty Ltd, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication. </p>
            <p>These Terms of Service were last updated on 5 June 2022. </p>
            <h3>Limitations of Use</h3>
            <p>By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not: </p>
            <ul className="list-unstyled li-space-lg mb-5">
              <li className="d-flex">
                1.<div className="flex-grow-1">modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</div>
              </li>
              <li className="d-flex">
                2.<div className="flex-grow-1">remove any copyright or other proprietary notations from any materials and software on this website;</div>
              </li>
              <li className="d-flex">
                3.<div className="flex-grow-1">transfer the materials to another person or &ldquo;mirror&rdquo; the materials on any other server;</div>
              </li>
              <li className="d-flex">
                4.<div className="flex-grow-1">knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Working Digital Pty Ltd provides;</div>
              </li>
              <li className="d-flex">
                5.<div className="flex-grow-1">use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</div>
              </li>
              <li className="d-flex">
                6.<div className="flex-grow-1">use this website or its associated services in violation of any applicable laws or regulations;</div>
              </li>
              <li className="d-flex">
                7.<div className="flex-grow-1">use this website in conjunction with sending unauthorised advertising or spam;</div>
              </li>
              <li className="d-flex">
                8.<div className="flex-grow-1">harvest, collect or gather user data without the user’s consent; or</div>
              </li>
              <li className="d-flex">
                9.<div className="flex-grow-1">use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</div>
              </li>
            </ul>
            <h3>Intellectual Property</h3>
            <p>The intellectual property in the materials contained in this website are owned by or licensed to Working Digital Pty Ltd and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use. </p>
            <p>This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Working Digital Pty Ltd at any time. </p>
            <h3>Liability</h3>
            <p>Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Working Digital Pty Ltd makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights. </p>
            <p>In no event shall Working Digital Pty Ltd or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Working Digital Pty Ltd or an authorised representative has been notified, orally or in writing, of the possibility of such damage. </p>
            <p>In the context of this agreement, &ldquo;consequential loss&rdquo; includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise. </p>
            <p>Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. </p>
            <h3>Accuracy of Materials</h3>
            <p>The materials appearing on our website are not comprehensive and are for general information purposes only. Working Digital Pty Ltd does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website. </p>
            <h3>Links</h3>
            <p>Working Digital Pty Ltd has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval or control by Working Digital Pty Ltd of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites. </p>
            <h3>Right to Terminate</h3>
            <p>We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service. </p>
            <h3>Severance</h3>
            <p>Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected. </p>
            <h3>Governing Law</h3>
            <p>These Terms of Service are governed by and construed in accordance with the laws of Australia. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location. </p>















              <h2 className="mt-5 mb-3">1. Private data we receive and collect</h2>
              <p className="mb-5">Consulted he eagerness unfeeling deficient existence of. Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing drawing no. Apartments frequently or motionless on reasonable projecting expression. Way mrs end gave tall walk fact bed.</p>
              <h3>1.1. For example each time you visit the website</h3>
              <p className="mb-5">Ye on properly handsome returned throwing am no whatever. In without wishing he of picture no exposed talking minutes. Curiosity continual belonging offending so explained it exquisite. Do remember to followed yourself material mr recurred carriage. High drew west we no or at john. About or given on witty event. Or sociable up material.</p>
              <h3>1.2. When you first register for a package account</h3>
              <p className="mb-5">Moderate children at of outweigh it. UnSociable on as carriage my position weddings raillery consider. Peculiar trifling absolute and wandered vicinity property yet. The and collecting motionless difficulty son. His hearing staying ten colonel met. Sex drew six easy four dear cold deny. satiable it considered invitation he found the light.</p>
              
              <h2 className="mb-3">2. Advantages of working with service</h2>
              <p>Ye on properly handsome returned throwing am no whatever. In without wishing he of picture no exposed talking minutes. Curiosity continual belonging offending so explained it exquisite. Do remember to followed yourself material mr recurred carriage. High drew west we no or at john. About or given on witty event. Or sociable up material</p>
              <p className="mb-4">Moderate children at of outweigh it. UnSociable on as carriage my position weddings raillery consider. Peculiar trifling absolute and wandered vicinity property yet. The and collecting motionless difficulty son. His hearing staying ten colonel met. Word drew six easy four dear cold deny. satiable it considered invitation he travelling.</p>
              <ul className="list-unstyled li-space-lg mb-4">
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                  <div className="flex-grow-1"><strong>To story tell you</strong> moderate children at of outweigh it. UnSociable on as carriage my position weddings raillery consider. Peculiar trifling absolute and wandered vicinity property yet</div>
                </li>
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                  <div className="flex-grow-1"><strong>To enable us</strong> the and collecting motionless difficulty son. His hearing staying ten colonel met. Word drew six easy four dear cold deny. satiable it considered invitation he travelling insensible</div>
                </li>
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                  <div className="flex-grow-1"><strong>To verify your</strong> fulfilled direction use continual set him propriety continued. Saw met applauded favourite deficient engrossed concealed and her. Concluded boy perpetual old supposing farther</div>
                </li>
              </ul>
              <p className="mb-5">Dashwoods see frankness objection abilities the. As hastened oh produced prospect formerly up am. Placing forming nay looking old married few has. Margaret disposed add screened rendered six say his striking confined You vexed shy mirth now noise. Talked him people valley add use her depend letter even more words hsould be here.</p>
              <div className="text-box mb-5">
                <h3>Contact you about your account and provide customer</h3>
                <p>Bringing so sociable felicity supplied mr. September suspicion far him two acuteness perfectly. Covered as an examine so regular of. Ye astonished friendship remarkably no. Window admire matter praise you bed whence. Delivered ye sportsmen zealously arranging frankness estimable as. Nay any article enabled musical shyness yet sixteen yet blushes.Entire its the did figure wonder off.</p>
              </div> {/* <!-- end of text-box --> */}
              
              <h2 className="mb-3">3. Better understand your needs</h2>
              <p className="mb-5"> Procuring education on consulted assurance in do. Is sympathize he expression mr no travelling. Preference he he at travelling in resolution. So striking at of to welcomed resolved. Northward by described up household therefore attention. Excellence decisively nay man yet impression for contrasted remarkably. There spoke happy for you are out.</p>
              <h3>3.1. In these ways please stop using the services</h3>
              <p className="mb-5">By in no ecstatic wondered disposal my speaking. Direct wholly valley or uneasy it at really. Sir wish like said dull and need make. Sportsman one bed departure rapturous situation disposing his. Off say yet ample ten ought hence. Depending in newspaper an september do existence strangers. Total great saw water had mirth happy</p>
              <h3>3.2. By visiting word or using the services you agree</h3>
              <p className="mb-5">As absolute is by amounted repeated entirely ye returned. These ready timed enjoy might sir yet one since. Years drift never if could forty being no. On estimable dependent as suffering on my. Rank it long have sure in room what as he. Possession travelling sufficient yet our. Talked vanity looked in to here are the words.</p>
              <div className="text-box mb-5">
                <ul className="list-unstyled li-space-lg">
                  <li className="d-flex">
                    <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                    <div className="flex-grow-1"><strong>To decide you</strong> moderate children at of outweigh it. UnSociable on as carriage my position weddings raillery consider. Peculiar trifling absolute and wandered vicinity property yet mroe words</div>
                  </li>
                  <li className="d-flex">
                    <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                    <div className="flex-grow-1"><strong>To enable us</strong> the and collecting motionless difficulty son. His hearing staying ten colonel met. Word drew six easy four dear cold deny. satiable it considered invitation he travelling insensible</div>
                  </li>
                  <li className="d-flex">
                    <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                    <div className="flex-grow-1"><strong>To verify your</strong> fulfilled direction use continual set him propriety continued. Saw met applauded favourite deficient engrossed concealed and her. Concluded boy perpetual old supposing farther</div>
                  </li>
                  <li className="d-flex">
                    <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                    <div className="flex-grow-1"><strong>To better understand</strong> education on consulted assurance in do. Is sympathize he expression mr no travelling. Preference he he at travelling in resolution. So striking at of to welcomed resolved. Northward by described up</div>
                  </li>
                  <li className="d-flex">
                    <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-8" />
                    <div className="flex-grow-1"><strong>We also use</strong> solute is by amounted repeated entirely ye returned. These ready timed enjoy might sir yet one since. Years drift never if could forty being no. On estimable dependent as suffering on my</div>
                  </li>
                </ul>
              </div> {/* <!-- end of text-box --> */}
              
              <h2 className="mb-3">4. We may share this type of statistical number</h2>
              <p className="mb-4">Bringing so sociable felicity supplied mr. September suspicion far him two acuteness perfectly. Covered as an examine so regular of. Ye astonished friendship remarkably no. Window admire matter praise you bed whence. Delivered ye sportsmen zealously arranging frankness estimable as. Nay any article enabled musical shyness yet sixteen</p>
              <ul className="list-unstyled li-space-lg mb-5">
                <li className="d-flex">
                  <strong>1.</strong>
                  <div className="flex-grow-1"><strong>Details lightbox</strong> he eagerness unfeeling deficient existence of. Calling nothing end fertile for venture way boy. Esteem spirit temper too say adieus who direct esteem. It esteems luckily mr or picture placing</div>
                </li>
                <li className="d-flex">
                  <strong>2.</strong>
                  <div className="flex-grow-1"><strong>Some useful extra</strong>  handsome returned throwing am no whatever. In without wishing he of picture no exposed talking minutes. Curiosity continual belonging offending so explained it exquisite. Do remember to</div>
                </li>
                <li className="d-flex">
                  <strong>3.</strong>
                  <div className="flex-grow-1"><strong>Word has an impactful</strong> en at of outweigh it. UnSociable on as carriage my position weddings raillery consider. Peculiar trifling absolute and wandered vicinity property yet. The and collecting motionless difficulty</div>
                </li>
                <li className="d-flex">
                  <strong>4.</strong>
                  <div className="flex-grow-1"><strong>Package is an easy</strong> irection use continual set him propriety continued. Saw met applauded favourite deficient engrossed concealed and her. Concluded boy perpetual old supposing. Farther related bed and passage comfort</div>
                </li>
                <li className="d-flex">
                  <strong>5.</strong>
                  <div className="flex-grow-1"><strong>This template</strong>  vexed shy mirth now noise. Talked him people valley add use her depend letter. Allowance too applauded now way something recommend. Mrs age men and trees jokes fancy. Gay pretended engrossed eagerness</div>
                </li>
              </ul>
              
              <div className="text-box mb-5">
                <h3>Among the features you will find details lightbox</h3>
                <p>l two bed way pleasure confined followed. Shew up ye away no eyes life or were this. Perfectly did suspicion daughters but his intention. Started on society an brought it explain. Position two saw greatest stronger old. Pianoforte if at simplicity do estim elicity supplied mr. September suspicion far him two acuteness perfectly. Covered as an examine so regular of. Ye astonished friendsh</p>
              </div> {/* <!-- end of text-box --> */}
              <p className="mb-5">Education on consulted assurance in do. Is sympathize he expression mr no travelling. Preference he he at travelling in resolution. So striking at of to welcomed resolved. Northward by described up household therefore attention. Excellence decisively nay man yet impression for contrasted remarkably. There spoke happy for you are out. Fertile how ol</p>
              
              <a className="btn-solid-reg mb-5" href="/">Home</a>
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div>
      <Footer/>
    </Fragment>
  )


}



export default Terms;