import React, { useState, Fragment } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
// import Auth from '@aws-amplify/auth';
// import { withRouter } from './router/withRouter';
// import { useAuth } from '../AuthProvider';


const Header = (props) => {
  const [mobileNavClosed, setMobileNavClosed] = useState(true);
  // console.log('useAuth() header');
  // console.log(useAuth());

  // R E F E R E N C E
  // const props.auth = {
  //   user,
  //   logout,
  //   openLogin,
  //   openSignup,
  //   openProfile,
  //   isLoading,
  //   getAccessToken
  // };

  // const {
  //   user,
  //   logout,
  //   openLogin,
  //   // openSignup,
  //   openProfile,
  //   isLoading
  // } = useAuth();
  // console.log('isLoading');
  // console.log(isLoading);

  // const blah = useAuth();
  // console.log('blah');
  // console.log(blah);


  // useEffect(() => {
  //   console.log('props');
  //   console.log(props);
    // Navbar on mobile
    // let elements = document.querySelectorAll(".navbar-nav .nav-link:not(.dropdown-toggle)");

    // for (let i = 0; i < elements.length; i++) {
    //   elements[i].addEventListener("click", () => {
    //     document.querySelector(".offcanvas-collapse").classList.toggle("open");
    //   });
    // }


    // document.querySelector(".navbar-toggler").addEventListener("click", () => {
    //   document.querySelector(".offcanvas-collapse").classList.toggle("open");
    // });

    // // On hover
    // const dropdownCheck = document.querySelector('.dropdown');

    // if (dropdownCheck !== null) { 
    //   // document.querySelector(".dropdown").addEventListener("mouseleave", toggleDropdown);
    //   // document.querySelector(".dropdown").addEventListener("mouseover", toggleDropdown);

    //   // On click
    //   document.querySelector(".dropdown").addEventListener("click", (e) => {
    //     const _d = e.target.closest(".dropdown");
    //     let _m = document.querySelector(".dropdown-menu", _d);
    //     if (_d.classList.contains("show")) {
    //       _m.classList.remove("show");
    //       _d.classList.remove("show");
    //     } else {
    //       _m.classList.add("show");
    //       _d.classList.add("show");
    //     }
    //   });
    // }
  // }, [props, isLoading]);


  // Hover on desktop
  const toggleDropdownHover = e => {
    const _d = e.target.closest(".dropdown");
    let _m = document.querySelector(".dropdown-menu", _d);

    setTimeout(
      function () {
      const shouldOpen = _d.matches(":hover");
      _m.classList.toggle("show", shouldOpen);
      _d.classList.toggle("show", shouldOpen);

      _d.setAttribute("aria-expanded", shouldOpen);
      },
      e.type === "mouseleave" ? 300 : 0
    );
  }
  // Click on desktop
  const toggleDropdownClick = e => {
    const _d = e.target.closest(".dropdown");
    let _m = document.querySelector(".dropdown-menu", _d);
    if (_d.classList.contains("show")) {
      _m.classList.remove("show");
      _d.classList.remove("show");
    } else {
      _m.classList.add("show");
      _d.classList.add("show");
    }
  }

  const toggleMobileNav = () => {
    setMobileNavClosed(!mobileNavClosed);
  }

  const toggleAndSignUp = () => {
    toggleMobileNav();
    props.auth.openSignup();
  }


  // const handleLogout = async event => {
  //   console.log('loggggggging out');
  //   event.preventDefault();
  //   try {
  //     Auth.signOut();
  //     props.auth.setAuthStatus(false);
  //     props.auth.setUser(null);
  //     this.props.navigate("/");
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  return (

    //<!-- Navigation -->
    // {!user && (
    // {!props.auth.isAuthenticated && (
    
    // {user && (
    // {props.auth.isAuthenticated && props.auth.user && (
      
    <Navbar id="navbarExample" fixed="top" variant="dark" expand="lg" aria-label="Main navigation">
      <Container>
        <Navbar.Brand className="logo-text" href="/">Nahwall</Navbar.Brand>
        <Navbar.Toggle id="navbarSideCollapse" className="p-0 border-0" onClick={toggleMobileNav}>
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        {/* <Navbar.Collapse id="navbarsExampleDefault" className="offcanvas-collapse"> */}
        <div className={mobileNavClosed ? "navbar-collapse offcanvas-collapse" : "navbar-collapse offcanvas-collapse open"} id="navbarsExampleDefault">
          <Nav className="ms-auto" navbarScroll>
            {(!props.auth.user && !props.auth.isLoading) && (
              <Fragment>
                <Nav.Item>
                  <Nav.Link href="/" onClick={toggleMobileNav}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/#features" onClick={toggleMobileNav}>Features</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/#pricing" onClick={toggleMobileNav}>Pricing</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link href="/faq" onClick={toggleMobileNav}>FAQ</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  {/* <a className="btn-outline-sm" href="/#" onClick={toggleAndSignUp}>Sign Up</a> */}
                  <a className="btn-outline-sm" href="/#launch">I'm Interested</a>
                </Nav.Item>
                {/* <Nav.Item> */}
                  {/* <Nav.Link href="/login">Log In</Nav.Link> */}
                  {/* <Nav.Link onClick={props.auth.openLogin}>Log In</Nav.Link> */}
                {/* </Nav.Item> */}
              </Fragment>
            )}
            {(props.auth.user && !props.auth.isLoading) && (
              <Fragment>
                <li className="nav-item dropdown" onClick={toggleDropdownClick} onMouseLeave={toggleDropdownHover} onMouseOver={toggleDropdownHover}>
                    <a className="nav-link dropdown-toggle" href="/#" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false">Account</a>
                    <ul className="dropdown-menu" aria-labelledby="dropdown01">
                        <li><a className="dropdown-item" onClick={props.auth.openProfile} href="/#">Profile</a></li>
                        {/* <li><a className="dropdown-item" href="/#">Profile</a></li> */}
                        <li><div className="dropdown-divider"></div></li>
                        {/* <li><a className="dropdown-item" onClick={handleLogout} href="/">Log Out</a></li> */}
                        <li><a className="dropdown-item" onClick={props.auth.logout} href="/#">Log Out</a></li>
                    </ul>
                </li>
                <Nav.Item>
                  <Nav.Link href="/contact">Support</Nav.Link>
                </Nav.Item>
              </Fragment>
            )}
          </Nav>
        </div>
      </Container>
    </Navbar>
  )
}

// export default withRouter(Header);
export default Header;