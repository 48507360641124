import React, { Fragment } from 'react';
import { useAuth } from '../AuthProvider';
import Header from './Header';
import Footer from './Footer';
import HeaderEx from './utility/HeaderEx';



const Privacy = () => {
  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  } = useAuth();
  // console.log('useAuth() Privacy');
  const authProps = {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  };

  return (
    <Fragment>
      <Header auth={authProps}/>
      <HeaderEx title="Contact Us"></HeaderEx>
      <div className="ex-basic-1 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">
              <div id="support-form"></div>
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div>
      <Footer/>
    </Fragment>
  )


}

export default Privacy;