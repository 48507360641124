import React, {useEffect} from 'react';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import './App.css';
// import Header from './components/Header';
import Home from './components/Home';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Contact from './components/Contact';
import Faq from './components/Faq';
// import Welcome from './components/Welcome';
// import Login from './components/auth/Login';
// import Register from './components/auth/Register';
// import Footer from './components/Footer';
// import Auth from '@aws-amplify/auth';
import AuthProvider from './AuthProvider';
// import { useAuth } from './AuthProvider';


const App = () => {

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [isInitialising, setIsInitialising] = useState(true);
  // const [user, setUser] = useState(null);

  // console.log('useAuth()');
  // console.log(useAuth());
  // const thingy = useAuth();
  // console.log('thingy');
  // console.log(thingy);

  useEffect(() => {
    // const session = await Auth.currentSession();
    // console.log(session.getIdToken());
    // let accessToken = session.getAccessToken()
    // let jwt = accessToken.getJwtToken()
    // console.log(accessToken);
    // console.log(jwt);

    // console.log(session);
    window.addEventListener('scroll', scrollFunction);
    window.addEventListener('scroll', scrollFunctionBTT);
    // (async () => {
    //   try {
    //     // scroll collapsing
    //     window.addEventListener('scroll', scrollFunction);
    //     window.addEventListener('scroll', scrollFunctionBTT);
    //     // scrollFunction();

    //     // const user = await Auth.currentAuthenticatedUser();
    //     // setUser(user);
    //     // setIsAuthenticated(true);


    //   } catch (error) {
    //     console.log(error.message);          
    //   }
    //   finally {
    //     // console.log('boom');
    //     // setIsInitialising(false);
    //   }

    // })();

  }, []);

  const scrollFunction = () => {
    if (document.documentElement.scrollTop > 30) {
      document.getElementById("navbarExample").classList.add("top-nav-collapse");
    } else if ( document.documentElement.scrollTop < 30 ) {
      document.getElementById("navbarExample").classList.remove("top-nav-collapse");
    }
  }

  /* Back To Top Button */

  // When the user scrolls down 20px from the top of the document, show the button
  const scrollFunctionBTT = () => {
    // Get the button
    var myButton = document.getElementById("myBtn");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      myButton.style.display = "block";
    } else {
      myButton.style.display = "none";
    }
  }
  // When the user clicks on the button, scroll to the top of the document
  const topFunction = () => {
    document.body.scrollTop = 0; // for Safari
    document.documentElement.scrollTop = 0; // for Chrome, Firefox, IE and Opera
  }

  const authProps = {
    // isAuthenticated: isAuthenticated,
    // user: user,
    // setAuthStatus: setIsAuthenticated,
    // setUser: setUser
  }
  // console.log(authProps);

  return (
    // !isInitialising &&
    <div className="App">
      <BrowserRouter>
        
        {/* <Header auth={authProps} /> */}
        <Routes>
          <Route path="/" element={<AuthProvider><Outlet /></AuthProvider>}>
          {/* <Fragment><Header auth={authProps} /></Fragment> */}
          {/* <Route path="/" element={<Outlet />}> */}
            <Route>
              <Route index element={<Home auth={authProps} />} />
              <Route path="/privacy" element={<Privacy auth={authProps} />} />
              <Route path="/terms" element={<Terms auth={authProps} />} />
              <Route path="/contact" element={<Contact auth={authProps} />} />
              <Route path="/faq" element={<Faq auth={authProps} />} />
              {/* <Route path="/welcome" element={<Welcome auth={authProps} />} />
              <Route path="/login" element={<Login auth={authProps} />} />
              <Route path="/register" element={<Register auth={authProps} />} /> */}
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
      {/* <Footer /> */}
      {/* <!-- Back To Top Button --> */}
      <button onClick={topFunction} id="myBtn">
        <img src="images/up-arrow.png" alt="alternative" />
      </button>
      {/* <!-- end of back to top button --> */}

    </div>  
  )
}

export default App;