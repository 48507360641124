import React, { Fragment } from 'react';
import { useAuth } from '../AuthProvider';
import UserHome from './UserHome';
import PublicHome from './PublicHome';
import Header from './Header';
import Footer from './Footer';

const Home = () => {
  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  } = useAuth();
  // console.log('useAuth() Home');
  const authProps = {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  };

  return (
    // !isLoading &&
    <Fragment>
      {!isLoading && user && (
        <Fragment>
          <Header auth={authProps}/>
          <UserHome auth={authProps}></UserHome>
          <Footer/>
        </Fragment>
      )}
      {!isLoading && !user && (
        <Fragment>
          <Header auth={authProps}/>
          <PublicHome auth={authProps}></PublicHome>
          <Footer/>
        </Fragment>
      )}
    </Fragment>
  )


}



export default Home;