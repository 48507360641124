import React, { useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import config from '../config.json';
import HeaderEx from './utility/HeaderEx';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import timezones from 'timezones-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';


const UserHome = (props) => {
  // R E F E R E N C E
  // const props.auth = {
  //   user,
  //   logout,
  //   openLogin,
  //   openSignup,
  //   openProfile,
  //   isLoading,
  //   getAccessToken
  // };

  // generally waiting for the server to do something
  const [isAwitingServerResponse, setIsAwitingServerResponse] = useState(false);

  // loading the list of accounts
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(true);
  const [linkedAccounts, setLinkedAccounts] = useState([]);
  const [canAddAccounts, setCanAddAccounts] = useState(false);

  // loading the user profile information
  const [isLoadingUserProfile, setIsLoadingUserProfile] = useState(true);
  const [userProfile, setUserProfile] = useState({});

  useEffect(() => {
    // (async () => {
    //   try {
    //     await getLinkedAccounts();
    //     await getUserProfile();
    //   }
    //   catch (error) {
    //     console.log(error.message);
    //   }
    // })();
    try {
      getLinkedAccounts();
      getUserProfile();
    }
    catch (error) {
      console.log(error.message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerParams = {
    headers: {
      Authorization: props.auth.getAccessToken()
    }
  }


  const handleDeleteAccount = async (event, accountId) => {
    event.preventDefault();
    setIsAwitingServerResponse(true);
    // console.log(event.target.getAttribute('data-id'));
    // console.log(accountId);
    // const session = await Auth.currentSession();
    // let authToken = session.getIdToken().getJwtToken();
    // console.log('authToken');
    // console.log(authToken);
    try {
      await axios.delete(`${config.api.invokeUrl}/account/${accountId}`, headerParams);
      await getLinkedAccounts();
    } catch (error) {
      console.log(error.message);
    }
    setIsAwitingServerResponse(false);
  }

  const handleAddNewAccount = async (event, accountType) => {
    event.preventDefault();

    setIsAwitingServerResponse(true);

    try {
      // const session = await Auth.currentSession();
      // let authToken = session.getIdToken().getJwtToken();
      const response = await axios.post(`${config.api.invokeUrl}/account`, {accountType}, headerParams);
      window.location.href = response.data.location;
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleSubscriptionChange = async (event, accountId, subscriptionId) => {

    try {
      if (event.target.checked === true) {
        // we need to subscribe
        setIsAwitingServerResponse(true);
        // const session = await Auth.currentSession();
        // let authToken = session.getIdToken().getJwtToken();
        const response = await axios.post(`${config.api.invokeUrl}/account/${accountId}/subscription`, {}, headerParams);
        
        setIsAwitingServerResponse(false);
        setLinkedAccounts(response.data);

      }
      else if (event.target.checked === false) {
        // we need to unsubscribe
        setIsAwitingServerResponse(true);
        // const session = await Auth.currentSession();
        // let authToken = session.getIdToken().getJwtToken();
        const response = await axios.delete(`${config.api.invokeUrl}/account/${accountId}/subscription/${subscriptionId}`, headerParams);
        
        setIsAwitingServerResponse(false);
        setLinkedAccounts(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  const getLinkedAccounts = async () => {
    // console.log('linkedies');
    try {
      // const session = await Auth.currentSession();
      // let authToken = session.getIdToken().getJwtToken();
      // console.log(Outseta.getAccessToken());
      // console.log('authToken');
      // console.log(authToken);
      const response = await axios.get(`${config.api.invokeUrl}/account`, headerParams);
      // const response = await axios.get(`https://0o957q46g9.execute-api.ap-southeast-2.amazonaws.com/dev/nwGetAccounts`, headerParams);
      if (response.data) {
        if (response.data.length >= config.limits.maxAccounts) {
          setCanAddAccounts(false);
        }
        else {
          setCanAddAccounts(true);
        }
        setLinkedAccounts(response.data);

      }
    } catch (error) {
      console.log(error.message);
    }
    setIsLoadingAccounts(false);
  }

  const getUserProfile = async () => {
    try {
      const response = await axios.get(`${config.api.invokeUrl}/user/${props.auth.user.Uid}`, headerParams);
      // console.log(response.data);
      if (response.data) {
        setUserProfile(response.data);
      }
      else {
        // just assume sydney
        setUserProfile({timezone: 'Australia/Sydney'});
      }
    } catch (error) {
      console.log(error.message);
    }
    setIsLoadingUserProfile(false);
  }


  const handleTimezoneChange = async (event) => {
    // console.log(event.target.value);

    const newUserProfile = {
      ...userProfile,
      timezone: event.target.value
    };
    
    // update state
    setUserProfile(newUserProfile);

    // push to the server
    setIsAwitingServerResponse(true);
    try {
      await axios.put(`${config.api.invokeUrl}/user/${props.auth.user.Uid}`, newUserProfile, headerParams);
      // console.log(response.data);
    } catch (error) {
      console.log(error.message);
    }
    setIsAwitingServerResponse(false);


  }

  return (
    <Fragment>
      <HeaderEx title="Dashboard" />
      <Container className="px-4 py-5">

        <div className="section-title pb-2">CONNECT AN ACCOUNT</div>
        <div className="pb-4">
          {canAddAccounts && (
            <Fragment>
              <Button variant="link" disabled={isAwitingServerResponse} onClick={(event) => handleAddNewAccount(event, 'google')} >
                <img className="img-fluid" src="images/signin-google.png" alt="alternative" />
              </Button>
              <br/>
              <Button variant="link" disabled={isAwitingServerResponse} onClick={(event) => handleAddNewAccount(event, 'microsoft')} >
                <img className="img-fluid" src="images/signin-microsoft.png" alt="alternative" />
              </Button>
            </Fragment>
          )}
          {!canAddAccounts && !isLoadingAccounts && (
            <Fragment>
              <p className="pb-2">You've already connected the maximum of {config.limits.maxAccounts} accounts. Surely you don't want any more accounts than that?</p>
              <p className="pb-2">Remove some accounts before adding more accounts or <a href="/contact"><strong>contact us</strong></a> for any issues</p>
            </Fragment>
          )}
        </div>

        <div className="section-title pb-2">LINKED ACCOUNTS</div>
        {isLoadingAccounts &&
          <Spinner className='spinner' animation="grow" />
        }
        <Row xs={1} md={2} className="g-4 pb-4">
          {linkedAccounts.map((account) => (
            <Col key={account.accountId}>
              <Card className="">
                <Card.Header>
                  <Row className="align-items-center">
                    <Col sm={11}>{account.displayName}</Col>
                    <Col sm={1}>
                      <Button className='float-end' variant="link" disabled={isAwitingServerResponse} onClick={(event) => handleDeleteAccount(event, account.accountId)} >                      
                        <FontAwesomeIcon icon={solid('trash-can')} />
                      </Button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted">{account.userName}</Card.Subtitle>
                  <Form>
                    <Form.Group>

                      {account.subscriptionId && (
                        <OverlayTrigger placement='right'
                          overlay={
                            <Tooltip>
                              Changes in subscribed calendars are pushed to all other calendars.
                            </Tooltip>
                        } >
                          <Form.Check 
                            type="switch"
                            id={account.accountId}
                            label="Subscribed"
                            checked
                            disabled={isAwitingServerResponse}
                            onChange={(event) => handleSubscriptionChange(event, account.accountId, account.subscriptionId)}
                          />
                        </OverlayTrigger>
                      )}
                      {!account.subscriptionId && (
                        <OverlayTrigger placement='right'
                        overlay={
                          <Tooltip>
                            Changes in subscribed calendars are pushed to all other calendars.
                          </Tooltip>
                        } >
                          <Form.Check 
                            type="switch"
                            id={account.accountId}
                            label="Not Subscribed"
                            disabled={isAwitingServerResponse}
                            onChange={(event) => handleSubscriptionChange(event, account.accountId, account.subscriptionId)}
                          />
                        </OverlayTrigger>
                      )}

                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <OverlayTrigger placement='right' overlay={<Tooltip>Needed for syncing Microsoft & Google for recurring events</Tooltip>} >
            <Col xs="auto"><div className="section-title pb-2">TIMEZONE</div></Col>
          </OverlayTrigger>
        </Row>
        {isLoadingUserProfile &&
          <Spinner className='spinner' animation="grow" />
        }
        {!isLoadingUserProfile &&
          <Row xs={1} md={2} className="g-4 pb-4">
            <Col>
              <Fragment>
                <Form.Select aria-label="Select Timezone" onChange={handleTimezoneChange} value={userProfile.timezone} disabled={isAwitingServerResponse}>
                {timezones.map((tz) => (
                  <option key={tz.tzCode} value={tz.tzCode}>{tz.label}</option>
                ))}
                </Form.Select>
              </Fragment>
            </Col>
          </Row>
        }


      </Container>
    </Fragment>
  )
}

export default UserHome;