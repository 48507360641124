import React from 'react';

const HeaderEx = (props) => {
  return (
    // <!-- Header -->
    <header className="ex-header">
      <div className="container">
        <div className="row">
          <div className="col-xl-10 offset-xl-1">
            <h1>{props.title}</h1>
          </div> {/* <!-- end of col --> */}
        </div> {/* <!-- end of row --> */}
      </div> {/* <!-- end of container --> */}
    </header>
    // <!-- end of header -->
  )
}

export default HeaderEx;