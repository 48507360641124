import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';

const Footer = () => {
  return (
    <Fragment>
      {/* <!-- Footer --> */}
      <div className="basic-3"></div>
      <div className="footer-decoration">
          <svg className="footer-frame" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 115.984"><defs><style>{`.cls-2{fill:#5f4dee;}`}</style></defs><title>footer-frame</title><path className="cls-2" d="M1616.28,381.705c-87.656,5.552-93.356-59.62-197.369-67.562-112.391-8.581-137.609,65.077-251.189,60.632-109.57-4.288-116.156-74.017-225.25-83.153-125.171-10.483-150,78.5-293,88.35-136.948,9.437-173.108-68.092-320.83-77.956C255.5,297.133,143,308.1,0,395.564V406.75H1920V324.537c-18-6.507-43.63-14.492-76.1-15.591C1740.655,305.452,1705.829,376.033,1616.28,381.705Z" transform="translate(0 -290.766)"/></svg>
      </div> {/* <!-- end of footer-decoration --> */}
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer-col first">
                <h6>About Website</h6>
                <ul className="list-unstyled li-space-lg p-small">
                  <li>Built with 💚 by one person in Sydney 🇦🇺</li>
                  <li>Born out of frustration with my calendars.</li>
                </ul>
              </div> {/* <!-- end of footer-col --> */}
              <div className="footer-col second">
                <h6>Links</h6>
                <ul className="list-unstyled li-space-lg p-small">
                  <li><a href="/terms">Terms of Service</a>, <a href="/privacy">Privacy Policy</a>, <a href="/faq">FAQ</a></li>
                  {/* <li>Useful: <a href="/#">Colorpicker</a>, <a href="/#">Icon Library</a>, <a href="/#">Illustrations</a></li>
                  <li>Menu: <a href="index.html">Home</a>, <a href="#features">Features</a>, <a href="#details">Details</a>, <a href="#contact">Contact</a></li> */}
                </ul>
              </div> {/* <!-- end of footer-col --> */}
              <div className="footer-col third">
                <a href="https://twitter.com/getnahwall" target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={brands('twitter')} size="2x" inverse />
                </a>
                {/* <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={solid('circle')} size="2x" inverse />
                  <FontAwesomeIcon icon={brands('twitter')} />
                </span>
                <span className="fa-layers fa-fw fa-lg">
                  <FontAwesomeIcon icon="fa-solid fa-circle" />
                  <FontAwesomeIcon icon="fa-solid fa-check" transform="shrink-6" inverse />
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-twitter fa-stack-1x"></i>
                  </a>
                </span>
                <span className="fa-stack fa-2x">
                  <i className="fa-solid fa-square fa-stack-2x"></i>
                  <FontAwesomeIcon icon={solid('circle')} size="2x" inverse />
                  <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                  <FontAwesomeIcon icon={brands('twitter')} size="1x" stack inverse />
                </span>
                <span className="fa-stack">
                  <a href="#your-link">
                    <i className="fas fa-circle fa-stack-2x"></i>
                    <i className="fab fa-instagram fa-stack-1x"></i>
                  </a>
                </span> */}
                <p className="p-small">Contact Us: <a href="/contact"><strong>nahwall.com/contact</strong></a></p>
              </div> {/* <!-- end of footer-col --> */}
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of footer --> */}  
      {/* <!-- end of footer --> */}


      {/* <!-- Copyright --> */}
      <div className="copyright">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p className="p-small">Copyright © <a href="https://workingdigital.com.au" target="_blank" rel="noreferrer">Working Digital Pty Ltd</a></p>
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- enf of row --> */}
        </div> {/* <!-- end of container --> */}
      </div> {/* <!-- end of copyright --> */} 
      {/* <!-- end of copyright --> */}
    </Fragment>
    // <footer className="py-3 my-4">
    //   <ul className="nav justify-content-center border-bottom pb-3 mb-3">
    //     <li className="nav-item"><a href="/#" className="nav-link px-2 text-muted">Home</a></li>
    //     <li className="nav-item"><a href="/#" className="nav-link px-2 text-muted">Features</a></li>
    //     <li className="nav-item"><a href="/#" className="nav-link px-2 text-muted">Pricing</a></li>
    //     <li className="nav-item"><a href="/#" className="nav-link px-2 text-muted">FAQs</a></li>
    //     <li className="nav-item"><a href="/#" className="nav-link px-2 text-muted">About</a></li>
    //   </ul>
    //   <p className="text-center text-muted">&copy; 2021 Company, Inc</p>
    // </footer>
  )
}

export default Footer;