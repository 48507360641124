import React, { Fragment } from 'react';
import { useAuth } from '../AuthProvider';
import Header from './Header';
import Footer from './Footer';
import HeaderEx from './utility/HeaderEx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';



const Privacy = () => {
  const {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  } = useAuth();
  // console.log('useAuth() Privacy');
  const authProps = {
    user,
    logout,
    openLogin,
    openSignup,
    openProfile,
    isLoading,
    getAccessToken
  };

  return (
    <Fragment>
      <Header auth={authProps}/>
      <HeaderEx title="Frequently Asked Questions"></HeaderEx>
      <div className="ex-basic-1 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1">
              {/* <div className="text-box mb-5">
                <p>Actually, no one's really asked these questions, but you might wanna ask</p>
              </div> */}
              <h2 className="mb-3">Nah...what?</h2>
              <p>Yeah, I know, it's a weird name. Plus it has "nah" in it which is, like, totally negative right? But I like the name for a few reasons:</p>
              <ul className="list-unstyled li-space-lg mb-3">
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-10" />
                  <div className="flex-grow-1">First up, I just thought it was funny</div>
                </li>
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-10" />
                  <div className="flex-grow-1">Secondly, it’s a play on "narwhal" which is like a unicorn in the sea and unicorns are cool and that’s for my kids</div>
                </li>
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-10" />
                  <div className="flex-grow-1">Thirdly, you have a firewall to block bad IT things, so I kinda liked the idea of a wall around your calendar which says "nah not available"</div>
                </li>
                <li className="d-flex">
                  <FontAwesomeIcon icon={solid('square-full')} transform="down-3 shrink-10" />
                  <div className="flex-grow-1">Last up, it reminded me of “yeah nah” from <a href="https://www.youtube.com/user/ozzymanreviews" target="_blank" rel="noreferrer">Ozzy Man</a></div>
                </li>
              </ul>
              <p>But mainly I just had to think of some name - and it turns out the name itself doesn't affect how the thing works.</p>
              {/* <h2 className="mb-3">Is this thing actually live yet?</h2> */}
              {/* <p>It's in private beta at the moment. If you're interested, <a href="/#launch"><strong>let us know you're keen</strong></a>, or just <a href="/contact"><strong>contact us</strong></a> for anything and we'll be in touch!</p> */}
              <h2 className="mb-3">Why do you need to access my calendars?</h2>
              <p>Believe me, nahwall is probably even less interested in the details of your calendars than you are (but we do take privacy very seriously). Basically, to be able to sync calendar events, we need to be able to read and write events in your calendars. We read events from one calendar, and write or create equivalent "block out" events in your other calendars. That's about it, we're not interested in who's invited to the event, who it's from, or the subject or description of the event. Just that the event exists, when it starts, and when it ends.</p>
              <h2 className="mb-3">Yeah fine, so what does it do? And what doesn't it do?</h2>
              <p>At the moment nahwall happily munches up events from both Google and Microsoft calendars. Nom nom nom. It handles single events, and recurring events. It also handles when events are updated or cancelled. The main thing it doesn't handle at the moment are exceptions to recurring events, and recurring events across a Google to Microsoft calendar, if those calendars are in different timezones. Turns out that's a bit tricky.</p>
              <h2 className="mb-3">What? No calendar view?</h2>
              <p>No, hell no, that's the last thing you need, yet another calendar to look at! Nahwall isn't trying to be a calendar client, just a helpful little animal that busily syncs events behind the scenes when you're not looking.</p>
              <h2 className="mb-3">Who made this?</h2>
              <p>A guy in Sydney, Australia, who, every 6 months for the last few years, tried to find a solution to juggling multiple calendars, and eventually decided to build it himself.</p>
              <a className="btn-solid-reg mb-5" href="/">Home</a>
            </div> {/* <!-- end of col --> */}
          </div> {/* <!-- end of row --> */}
        </div> {/* <!-- end of container --> */}
      </div>
      <Footer/>
    </Fragment>
  )


}

export default Privacy;